import tailwindColors from "tailwindcss/colors"
import {useLayoutStore} from "@/store/layout"

export const $getCurrentModule = () => {
    const module = useLayoutStore().currentModule
    return module ? appModules[module] : null
}

export const $getModuleIcon = (module?: string): string | null => {
    if (!module) return $getCurrentModule()?.icon ?? null
    return appModules[module]?.icon ?? null
}

export const $getModuleColor = (module?: string): string | null => {
    if (!module) return $getCurrentModule()?.color ?? null
    return appModules[module]?.color ?? null
}

export const $getModuleTextColor = (module?: string): string | null => {
    if (!module) return $getCurrentModule()?.color?.text ?? null
    return appModules[module]?.color?.text ?? null
}

export const $getModuleBackgroundColor = (module?: string): string | null => {
    if (!module) return $getCurrentModule()?.color?.background ?? null
    return appModules[module]?.color?.background ?? null
}

export const appColors = {
    ...tailwindColors,
    primary: {
        "50": "#f0faff",
        "100": "#e0f4fe",
        "200": "#b9ecfe",
        "300": "#7bdffe",
        "400": "#35cefb",
        "500": "#0bbaee",
        "600": "#0095ca",
        "700": "#0177a3",
        "800": "#056587",
        "900": "#0b526f",
        "950": "#07344a"
    }
}

export const appModules: AppModules = {
    primary: {
        name: 'primary',
        color: appColors.primary
    },
    dashboard: {
        name: 'dashboard',
        icon: 'heroicons:home'
    },
    telegram: {
        name: 'telegram',
        color: appColors.cyan,
        icon: 'ph:telegram-logo',
    },
    users: {
        name: 'users',
        icon: 'heroicons:users',
        color: appColors.indigo,
        search: {
            detail: 'users-id-userBaseData',
            nameProp: 'fullname'
        },
    },
    organisations: {
        name: 'organisations',
        icon: 'heroicons:user',
        color: appColors.teal,
        search: {
            detail: 'organisations-id-organisationBaseData'
        },
    },
    settings: {
        name: 'settings',
        color: appColors.slate,
        icon: 'heroicons:cog-6-tooth',
    },
}
